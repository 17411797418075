import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox, { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import Masonry from 'react-masonry-component';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';



// Masonry section
const masonryOptions = {
    transitionDuration: 0
};
const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end



class Gallery extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <div className="page-content">
                    <PageTitle motherMenu="Curriculum" activeMenu="Curriculum" />
                    <div className="content-block">
                        <div className="section-full content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="head-title text-secondry">Rainbow Kids Curriculum</h2>
                                    
                                </div>
                                
                                <div>
                                   
                                   

                                    <ul className="list-inline" style={{ fontSize: "18px" }}>
                                        <li><span>✅ &emsp;</span>Our curriculum is based on the idea that each child is a unique individual, acadamically we adopt play way method of learningand thematic approach.</li>
                                        <p></p>
                                        <li><span>✅ &emsp;</span>Social and emotional development to foster a child’s sense of self, enhance his or her ability to make friends and create openness to learning.</li>
                                        <p></p>
                                        <li><span>✅ &emsp;</span>Physical development to strengthen large and small muscles, coordinate movement and improve overall health and growth.</li>
                                        <p></p>
                                        <li><span>✅ &emsp;</span>Cognitive development to promote intellectual growth.</li>
                                        <p></p>
                                        <li><span>✅ &emsp;</span>Positive interactions between teachers and kids to develop linguistic skills and scientific approach to think and problem solve.</li>

                                    </ul>

                                    <ul className="list-inline" style={{ fontSize: "18px" }}>
                                        <li> ★ 9:30 to 10:00 Outdoor Play</li> <br/>
                                        

                                        <li> ★ 10:00 to 10:30  Circle Time</li>     <br />                         

                                        <li> ★ 10:30 to 11:00  Short Break</li>    <br />                          

                                        <li> ★ 11:00 to 12:00 Curriculum time</li>    <br />                              

                                        <li> ★ 12:00 to 12:30 Special activity time </li>    <br />                                    
										
                                    </ul>

                                </div>



                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}
export default Gallery;