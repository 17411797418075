import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
//images
import bnr1 from './../../images/line2.png';
import clsimg1 from './../../images/classes/pic1.jpg';
import clsimg2 from './../../images/classes/pic2.jpg';
import clsimg3 from './../../images/classes/pic3.jpg';
import clsimg4 from './../../images/classes/pic4.jpg';
import clsimg5 from './../../images/classes/pic5.jpg';
import clsimg6 from './../../images/classes/pic6.jpg';

const classesBlog =[
	{images: clsimg1, title: 'Playgroup', age:"2+", time:"10AM to 12:30PM"},	
	{ images: clsimg4, title: 'Nursery', age: "3+", time: "10AM to 12:30PM"},	
	{ images: clsimg3, title: 'LKG', age: "4+", time: "10AM to 1PM"},	
	{ images: clsimg5, title: 'UKG', age: "5+", time: "10AM to 1PM"},	
	{ images: clsimg2, title: 'Other Activities', age: "1.5+", time: "10AM to 5PM"},	
	{ images: clsimg6, title: 'Daycare', age: "0.6+", time: "8AM to 8PM"},	
];

class Classes extends Component{
	render(){
		return(
			<Fragment>
				<Header />
				<div className="page-content">
					<PageTitle  motherMenu="Classes"  activeMenu="Classes" />
					<div className="content-block">
						<div className="section-full bg-white content-inner" style={{backgroundImage:"url("+ bnr1+")", backgroundSize:"contain",backgroundRepeat: "no-repeat",backgroundPosition: "center"}}>
							<div className="container">
								<div className="row sp40">
									{classesBlog.map((data, index)=>(
										<div className="col-lg-4 col-md-6 col-sm-6" key={index}>
											<div className="class-item">
												<div className="class-media"> 
													<img src={data.images} alt=""/>
													<p>
														<span>Class Time:</span> 
														{data.time}
													</p>
												</div>
												<div className="class-info">
													<h4 style={{ color:"#ed5ab0"}}>{data.title}</h4>
													{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit nulla felis ipsum.</p> */}
													<ul className="schedule">
														<li className="bg-blue class-size"><span>Class Size</span> <span>8 - 10</span> </li>
														<li></li>
														<li className="bg-green years-old"><span>Years Old</span><span>&nbsp; &nbsp;{data.age }</span> </li>
														{/* <li className="bg-orange tution"><span>Tution Fee</span> <span>$1500</span> </li> */}
													</ul>
												</div>
											</div>
										</div>
									))}	
								</div>
							</div>
						</div>	
					</div>
				</div>
				<Footer />
			</Fragment>
		)
	}
}
export default Classes;