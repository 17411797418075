import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom'; 
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import Masonry from 'react-masonry-component';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 
import AccordionBlog from '../Element/AccordionBlog';
import TestiMonialSlider from '../Element/TestiMonialSlider';
import VideoPopup from '../Element/VideoPopup';
//Images
import bnr1 from './../../images/line2.png';
import bnr2 from './../../images/background/bg5.jpg';
import bgimg2 from './../../images/background/bg1.jpg';
import bnr4 from './../../images/line2.png';
import about from './../../images/about/about-1.jpg';
import about1 from './../../images/about/about-2.jpg';
import gallery1 from './../../images/gallery/pic1.jpg';
import gallery2 from './../../images/gallery/pic2.jpg';
import gallery3 from './../../images/gallery/pic3.jpg';
import gallery4 from './../../images/gallery/pic4.jpg';
import gallery5 from './../../images/gallery/pic5.jpg';
import gallery6 from './../../images/gallery/pic6.jpg';

// Masonry section
const masonryOptions = {                                          
    transitionDuration: 0
};
const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

//Light Gallery Icon
const Iconimage = props => {
	const { openLightbox } = useLightbox()
  return (
    <Link   onClick={() => openLightbox(props.imageToOpen)} className="" >
      <i className="fa fa-search icon-bx-xs"></i>
    </Link>
  )
}
const galleryBlog = [
	{image: gallery1},	
	{image: gallery2},	
	{image: gallery3},	
	{image: gallery4},	
	{image: gallery5},	
	{image: gallery6},	
];

function BackgroundBlog(){
	return(
		<>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-12  about-text"  style={{visibility: "visible", animationDuration: "2s", animationDelay: "0.2s", animationName: "fadeIn"}}>
						<div className="section-head text-center">
							<div className="video-play">
								{/* <VideoPopup /> */}
							</div>
							<h2>Let Your Kids Have an Amazing<br/>Time at the Park</h2>
							<Link to={"./contect-us"} className="btn btn-md radius-xl">Read More</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

function EducationBanner() {
	return (
		<>
			<div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{ backgroundImage: "url(" + bgimg2 + ")" }}>
				<div className="about-overlay-box"></div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="section-head">
								<h2 className="head-title text-yellow">Education from birth<br />begins with us</h2>
								<p className="text-white">It Is Our Pleasure To Introduce Rainbow Kids , Founded By A Blend Of Young Minds From Various Corporates And A Team Of Qualified And Experienced Facilitator From Reputed CBSE And ICSE Schools In The Panel, We Commit To Provide A Safe, Nurturing, And Stimulating Environment For Young Children.</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-12"></div>
					</div>
				</div>
			</div>
		</>
	)
}

class AboutUs1 extends Component{
	componentDidMount(){
		var splitBox = document.querySelectorAll('.split-box');
       
		var fSB = [].slice.call(splitBox);
		
		fSB.forEach(el => el.classList.add('split-active'));
	}
	render(){
		return(
			<Fragment>
				<Header />	
				 <div className="page-content">
					<PageTitle  motherMenu="About Us"  activeMenu="About Us" />
					<div className="content-block">
						<div className="section-full bg-white content-inner-2 about-area" style={{backgroundImage:"url("+ bnr1 +")", backgroundSize:"contain", backgroundRepeat: "no-repeat",backgroundPosition: "center"}}>
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-12 col-sm-12 m-b15">
										<div className="section-head">
											<h2 className="head-title text-secondry">About School</h2>
											<p>Rainbow kids boutique preschool is a distinct, one of its kind play school. Here, we offer.Here we offer intimate experience and personalized service. At Rainbowkids we are committed to providing the best learning space and best care for children. We provide excellent childcare in a safe, trusted and nurturing environment.
												<p></p>
												The way children learn, think and behave throughout their lives are shaped by early experiences. Through our warm, loving learning environment we are committed to supporting each child's individual growth and development to reach his or her highest potential. Our philosophy incorporates open and daily communication with parents as we believe our parents are our partners in building a new generation.</p>
										</div>
										{/* <AccordionBlog /> */}
									</div>
									<div className="col-lg-6 col-md-12 col-sm-12 teacher-content">
										<div className="split-box">
											<div className="about-media">
												<img src={about} alt=""/>
											</div>
										</div>
									</div>

									<div className="col-lg-6 col-md-12 col-sm-12 m-t20 teacher-content">
										<div className="split-box">
											<div className="about-media">
												<img src={about1} alt="" />
											</div>
										</div>
									</div>

									<div className="col-lg-6 col-md-12 col-sm-12 m-t20">
										<div className="section-head">
											<h2 className="head-title text-secondry">About Founder</h2>
											<p>
												Ms. Sandhya Prithvi, is a passionate Educator an Entrepreneur. She works towards the betterment of women and child Empowerment. She helps her clients create a safe and harassment-free workplace, spread awareness, drive effective training programs to women and children to empower them with required skillsets. She is an External Committee member for many organisations. She holds a Post graduate degree from the University of Mysore, She is a certified Early Educator.
												<p></p>
												She is the Founder of Rainbow Kreations & RainbowKids . Rainbow Kreations is a firm catering towards social and community development. It provides training, skill development and recruitment assistance to women in need.
												<p></p>
												The schools run by Sandhya prithvi  has been awarded as the best upcoming brand, Best preschool with value for money, Best curriculum in India consecutively for 3 years by Education today.
											</p>
										</div>
										{/* <AccordionBlog /> */}
									</div>


								</div>
							</div>
						</div>
						{/* <div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{backgroundImage:"url(" + bnr2 + ")"}}>
							<BackgroundBlog />
						</div> */}
						{/*  Portfolio  */}
						{/* <div className="section-full content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Gallery of our classes</h2>
									<p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p>
								</div>
								<SimpleReactLightbox>
									<SRLWrapper>
										<div className="clearfix" id="lightgallery">
											<ul id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery">
												<Masonry
													className={'my-gallery-class'} 
													options={masonryOptions} 
													disableImagesLoaded={false} 
													updateOnEachImageLoad={false} 
													imagesLoadedOptions={imagesLoadedOptions} // default {}
												>
													{galleryBlog.map((data,index)=>(
														<li className="web design card-container col-lg-4 col-md-6 col-sm-6 "  key={index}>
															<div className="dlab-box frame-box m-b30">
																<div className="dlab-thum dlab-img-overlay1"> 
																	<img src={data.image} alt="" />
																	<div className="overlay-bx">
																		<div className="overlay-icon"> 
																			<Iconimage />
																		</div>
																	</div>
																</div>
															</div>
														</li>
													))}
												</Masonry>		
											</ul>
										</div>
									</SRLWrapper>	
								</SimpleReactLightbox>	
							</div>
						</div> */}
						
						<EducationBanner />
						{/* <div className="section-full bg-white content-inner-2" style={{backgroundImage:"url(" + bnr4 +")", backgroundSize:"contain",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Testimonials about center</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<TestiMonialSlider />
							</div>
						</div> */}
					</div>
				</div>	
				<Footer />	
			</Fragment>
		)
	}
}
export { BackgroundBlog, EducationBanner };
export default AboutUs1;