import React,{Component} from 'react';
import Slider from "react-slick";
import grid1 from './../../images/testimonials/pic2.jpg';


const latestBlog = [
	{
		image: grid1,
		parentName: "Vinod Kumar",
		testText: 'As a parents, post pandemic we were bit worried about finding good play school, luckily we found rainbow 🌈 kid’s, first time when we met Sandhya Madam along with kid, we felt we are leaving our kid at right place and right hand, post admission we found lot of changes in terms of kid behaviour attitude and self confidence, over all we are really happy and we strongly recommend this school.'
	},
	{
		image: grid1,
		parentName: "Gagan Kaur",
		testText: "I got my child into the school when she was 2 years old. The simplicity of the school and its fine way to conduct the curriculum was the main attraction. The children to teacher ratio being 5:1, helped my child learn better. I would highly recommend this school for parents who dont want to be a part of the flashy schools and want their children to learn the basics of humanity. Thank you"
	},
	{
		image: grid1,
		parentName: "Anil Cherian",
		testText: "Rainbow Kids is a great play school! All the  teachers and administrators truly love what they do and care for all the students. They are very welcoming . It is a happy place that helps students feel excited about school in their early ages ..my daughter went through online and offline due to the pandemic situation and both we equally commendable... The overall management is also commendable and i do not have any negative comments on this place. Keep up the good work" ,
	},
	{
		image: grid1,
		parentName: "Chaithra",
		testText: "Rainbow kids management is very beautiful. Thanks to all teachers to push my child in all activities and being very friendly to my child. For sure rainbow kids is very strong basement to my child. Thanks to the hole management." ,
	},
	{
		image: grid1,
		parentName: "Bindhu Nambiar",
		testText: "Thank you so much teachers and school management  for your wonderful support and encouragement, we can see lot of improvement in our son Tej. Every day he is learning new things .we are very happy." ,
	},
	{
		image: grid1,
		parentName: "Keshava Murthy",
		testText: "Rainbow kids akshaynagar is the best pre school and we are really happy and proud that we have chosen rainbow kids for our daughter,the best safety center head and the best highly qualified staff.",
	}

]
class TestiMonialSlider extends Component{	
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}
	render(){
		var settings = {		
			arrows: false,
            slidesToShow: 1,			
            infinite: true,
			autoplay: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 1,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 1,
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
				  }
				}
			]
        };
		return(
			<>	
				<div className="sprite-nav">
					<Slider className="client-carousel owl-carousel owl-theme  " ref={c => (this.slider = c)} {...settings}>
						{latestBlog.map((item, index) => (
							<div className="item" key={index}>
								<div className="client-box">
									<div className="testimonial-detail clearfix">
										<div className="testimonial-pic radius">
											<img src={item.image} width="100" height="100" alt="" />
										</div>
										<h5 className="testimonial-name m-t0 m-b5">{item.parentName}</h5> 
										<span>Parent</span> 
									</div>
									<div className="testimonial-text">
										<p>{item.testText }</p>
									</div>
								</div>	
							</div>
						))}	
					</Slider>		
					<div className="owl-nav" style={{ textAlign: "center" }}>
						<div className=" owl-prev " onClick={this.previous}/>
						<div className="owl-next " onClick={this.next}/>
					</div>
				</div>	
			</>	
		)
	}
}
export default TestiMonialSlider;