import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import largeimg from './../../images/blog/large/pic1.jpg';

function ParagraphTop(){
	return(
		<>
			<p>
				Each morning, little X came wailing to the centre ,she was 2 .5 year old.  I remember her mum, face flushed, , hair in a frizz, dragging little x by one hand all along , while balancing the school bag , lunch box and a doll in the other. The mum was always worried, tired, and clearly mortified by the onlookers .
				Every day, as i greeted her near school gate, Mum would look at me with a pale smile and teary eyed herself, I would press her hand and assure her, It's fine! she will be alright!!.
			</p>
			<p></p>
			<p>
				Days turned to weeks , and weeks to months but the daily drama continued .
				We entered second term  and the story was the same.  No amount of activities , music dance nothing would really amuse the child.
			</p>
		</>
	)
}

function Paragraph() {
	return (
		<>
			<p>
				And one day the Education officer came, a dark fat and a short man with big tummy and bigger moustache. He wanted to say a hello to all our little ones, our kids not used to seeing strangers gave mixed reactions, some puzzled, some scared , some ignorant. nevertheless nobody seemed interested in answering his simple question of " what is your name" and bingo!!! out of the blue Little X stood up, folded hands, and very fluently told her name, we were pleasantly shocked, she wouldn't stop at that, she told him  all that she had over heard in the class,sitting in a corner by herself throughout. The  rhymes, the Sundays Mondays, the colours, the numbers...she spoke and spoke , her answers perfect, her words like pearls. I was elated, I couldn't resist myself from giving her a hug and plant a kiss on her cheeks . And from that day she never looked back.  And after 4 years she walked out of the school with best student award ...
				Every child is unique and have their own learning style and pace, but I can assure you they are all capable of learning and succeeding. as adults it's our responsibility to help them reach there. I am also grateful to the mum for trusting us throughout the child's tough phase.
			</p>
			
		</>
	)
}

class ClassDetailContent extends Component{
	render(){
		return(
			<Fragment>
				<div className="classes-details">
					<div className="class-media">
						<img src={largeimg} alt=""/>
					</div>
					<div className="class-info">
						<div className="dlab-post-title ">
							<h2 className="post-title m-t0">Better parenting and Better teaching</h2>
						</div>
						<div className="dlab-post-meta m-b20">
							<ul className="d-flex align-items-center">
								<li className="post-date"> <i className="la la-calendar-o"></i><strong>29 April</strong> <span> 2022</span> </li>
								<li className="post-author"><i className="la la-user"></i>By Sandhya </li>
								{/* <li className="post-comment"><i className="la la-comment"></i> <Link to={"/classes"}>0 Comments</Link> </li> */}
							</ul>
						</div>
						<div className="dlab-post-text">
							<ParagraphTop />	
							<blockquote>
								"It's alright, she will  be fine" I would say with a  smile but now i also was concerned about the child and her visibly stressed mother.
							</blockquote>
							<Paragraph />
							{/* <h4>Funny Kids</h4>	 */}
							{/* <Paragraph /> */}
						</div>
						{/* <div className="dlab-post-tags clear">
							<div className="post-tags"> 
								<Link className="mr-1" to={"#"}>Child </Link> 
								<Link className="mr-1" to={"#"}>Eduction </Link> 
								<Link className="mr-1" to={"#"}>Money </Link> 
								<Link className="mr-1" to={"#"}>Resturent </Link> 
								<Link className="mr-1" to={"#"}>Child </Link> 
								<Link className="mr-1" to={"#"}>Eduction </Link> 
								<Link className="mr-1" to={"#"}>Money </Link> 
								<Link className="" to={"#"}>Resturent </Link> 
							</div>
						</div> */}
					</div>
				</div>
			</Fragment>
		)
	}
}
export default ClassDetailContent;