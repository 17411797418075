import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import SideBar from '../Element/SideBar';
import ClassDetailContent from '../Element/ClassDetailContent';

import largeimg from './../../images/blog/large/pic4.jpg';

//images
import test1 from './../../images/testimonials/pic1.jpg';
import test2 from './../../images/testimonials/pic2.jpg';
import test3 from './../../images/testimonials/pic3.jpg';

const CommentBlog=({imageBlog})=>{
	return(
		<>
			<div className="comment-body">
				<div className="comment-author vcard"> <img className="avatar photo" src={imageBlog} alt="" /> 
					<cite className="fn">Stacy poe</cite> <span className="says">says:</span> 
				</div>
				<div className="comment-meta"> <Link to={"#"}>October 6, 2015 at 7:15 am</Link> </div>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neqnsectetur adipiscing elit. Nam viae neqnsectetur adipiscing elit.
					Nam vitae neque vitae sapien malesuada aliquet. </p>
				<div className="reply"> <Link to={"#"} className="comment-reply-link">Reply</Link> </div>
			</div>	
		</>	
	)
} 
class BlogDetails extends Component{
	render(){
		return(
			<Fragment>
				<Header />
				<div className="page-content">
					<PageTitle  motherMenu="Blog"  activeMenu="Blog" />
					<div className="content-block ">
					<div className="section-full bg-white content-inner">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-12 col-sm-12">
									{/* Classes Detail  */}
										<ClassDetailContent />
									{/* Classes Detail ENd */}
									
									
								</div>
									<div className="col-lg-4 col-md-12 col-sm-12 class-info">
										<div className="dlab-post-title ">
											<h2 className="post-title m-t0 ">My Write-ups</h2>
											<img src={largeimg} alt="" />
										</div>
									{/* <SideBar /> */}
								</div>
							</div>
						</div>
					</div>
					</div>
				</div>		
				<Footer />
			</Fragment>
		)
	}
}
export default BlogDetails;